import request from '../utils/request'

export default {
    async temp(data) {     // 获取设备体温数据
        return await request('/v1/data/temp', data, 'POST')
    },
    async heart(data) {      // 获取设备心率数据
        return await request('/v1/data/heart', data, 'POST')
    },
    async blood(data) {      // 获取设备血压数据
        return await request('/v1/data/blood', data, 'POST')
    },
    async breath(data) {     // 获取设备呼吸数据
        return await request('/v1/data/breath', data, 'POST')
    },
    async step(data) {     // 获取设备运动数据
        return await request('/v1/data/step', data, 'POST')
    },
    async sleep(data) {     // 获取睡眠数据
        return await request('/v1/data/sleep', data, 'POST')
    },
    async location(data) {   // 获取手环定位数据
        return await request('/v1/data/location', data, 'POST')
    },
    async list(data) {     // 获取手环列表
        return await request('/v1/data/list', data, 'POST')
    },
    async export(data) {     // 手环数据导出
        return await request('/v1/data/export', data, 'POST', 60000)
    },
    async user(data) {     // 获取用户统计数据
        return await request('/v1/data/panel_user', data, 'POST')
    },
    async panel(data) {     // 获取设备统计数据
        return await request('/v1/data/panel', data, 'POST')
    },
    async panelStat(data) {     // 获取用户数据统计数据
        return await request('/v1/data/panel_stat', data, 'POST')
    },
    async panelAlarm(data) {     // 获取告警统计数据
        return await request('/v1/data/panel_alarm', data, 'POST')
    },
    async screen(data) {     // 获取大屏数据
        return await request('/v1/data/screen', data, 'POST')
    },
    async map(data) {     // 获取大屏地图数据
        return await request('/v1/data/map', data, 'POST')
    },
    async oxygen(data) {
        return await request('/v1/data/oxygen', data, 'POST')
    },
    async pressure(data) {
        return await request('/v1/data/pressure', data, 'POST')
    },
    async locator(data) {
        return await request('/v1/data/locator', data, 'POST')
    },
    async transform(data) {
        return await request('/common/transform', data, 'POST')
    }
}
